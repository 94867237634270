@media print {
  .ui.menu {
    display: none !important;
  }
  .header,
  .ui.red.header {
    color: #000 !important;
    font-weight: normal !important;
    font-size: 16px !important;
    text-transform: none !important;
  }
  .segment {
    border: 0px !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .main {
    margin: 0px !important;
  }
  .column {
    padding: 3px !important;
  }
  .row {
    padding: 3px !important;
  }
  tfoot {
    display: none !important;
  }
  .table {
    padding: 0px !important;
    margin: 0px !important;
  }
  table {
    page-break-inside: auto !important;
  }
  tr {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }
  td {
    white-space: normal;
    word-wrap: break-word;
  }
  .table table {
    page-break-inside: auto !important;
  }
  .table tr {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }
  /* .table tr:nth-child(15n+15) {
        page-break-after: always !important;
    } */
  .show-on-print {
    display: initial !important;
  }
  .order-barcode {
    position: absolute;
    top: -40px;
    right: -10px;
  }
  .orderrowtable-print th:nth-child(1),
  .orderrowtable-print th:nth-child(2),
  .orderrowtable-print td:nth-child(1),
  .orderrowtable-print td:nth-child(2) {
    display: none;
  }
  .orderrowtable-print th:nth-child(6),
  .orderrowtable-print th:nth-child(6),
  .orderrowtable-print td:nth-child(6),
  .orderrowtable-print td:nth-child(6),
  .orderrowtable-print th:nth-child(7),
  .orderrowtable-print th:nth-child(7),
  .orderrowtable-print td:nth-child(7),
  .orderrowtable-print td:nth-child(7) {
    display: table-cell !important;
  }

  .alertrowtable-print th:nth-child(1),
  .alertrowtable-print th:nth-child(2),
  .alertrowtable-print td:nth-child(1),
  .alertrowtable-print td:nth-child(2) {
    display: none;
  }
  .alert-barcode {
    position: absolute;
    top: -40px;
    right: -10px;
  }
  .orderrowtable-print td:nth-child(5) {
    text-align: right;
  }

  .orderrowtable-print td,
  .alertrowtable-print td {
    padding: 5px !important;
  }
}
