/* Mobile */
@media only screen and (max-width: 767px) {
    [class*="mobile hidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large screen only"]:not(.mobile),
    [class*="widescreen only"]:not(.mobile),
    [class*="or lower hidden"] {
      display: none !important;
    }
  }