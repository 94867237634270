
.main.container {
    margin-top: 3.7em;
}
a:hover{
    text-decoration: underline;
}
.finnid-header{
    letter-spacing:2px !important;
    text-transform: uppercase !important;
    color:#ce0538 !important;
    margin-bottom:1em !important;
    position: relative;
}
.alert-message{
    left: 50%;
    width:300px;
    margin-left: -150px !important;
    position: fixed !important;
    bottom: 10%;
    z-index: 1000;
    word-wrap: break-word;
}
.fullwidth, .fullwidth * {
    width:100%;
    min-width: auto !important;
    max-width: auto !important;
}
.ui.table td.negative, .ui.table tr.negative {
    background: #ffe5e5!important;
    color: #9f3a38!important;
}
.ui.table td.positive, .ui.table tr.positive {
    background: #e7fbd6!important;
    color: #2c662d!important;
}
.ui.table td.warning, .ui.table tr.warning {
    background: #fbf4c3!important;
    color: #573a08!important;
}
.ui.page.modals.dimmer.transition.visible.active {
    display: flex !important;
}
.show-on-print{
    display: none;
}